import React from "react"
import Layout from "../layout/components/layout"
import ContentTable from "../layout/components/contentTable"
import Seo from "../layout/components/seo"


const Index = () => {

  return (
    <Layout>
      <Seo title="Blog of Wareless Group"/>
      <div>
        <p>
          This is the blog of Wareless Group. The posts in this site mainly are technology-related.
        </p>
        <p>
          这个网站是 Wareless Group 的博客主要是技术相关的内容。
        </p>
      </div>
      <ContentTable />
    </Layout>
  )
}

export default Index;
